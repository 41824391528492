import Immutable from 'immutable'
import { categoryToCategoryItem } from './categoryTransformers'
import { getItemsByIds } from '../../selectors/itemsSelector'
import { selectCategoryById } from '../../selectors/categoriesSelector'
import { selectTagItemsBySlugs } from '../../selectors/tagsSelector'
import { selectUserBlacklistedSourceIds } from '../../selectors/userSelector'
import { logException } from '../../sentry'

export function itemIdsToItems(state, itemIds, batches = null) {
  const items = getItemsByIds(state, itemIds)
  return items.map(item => itemToNewsListItem(item, state, batches))
}

function itemToNewsListItem(item, state, batches = null) {
  const {
    id,
    title,
    link,
    source,
    sourceId,
    tags,
    categoryId,
    timestamp,
    clicks,
    plus_votes,
    minus_votes,
    currentVote,
    currentReport,
    batchId,
    custom,
    paywalled,
  } = item

  let batch
  try {
    // No need to create batch Map when there is only one item. This check should also eliminate the exception when
    // batchId is not present in batches, making the try/catch unnecessary
    if (batches && batches.getIn([batchId, 'size']) > 1) {
      batch = Immutable.Map({
        id: batchId,
        size: batches.getIn([batchId, 'size']),
        loading: batches.getIn([batchId, 'loading']),
        items: itemIdsToItems(state, batches.getIn([batchId, 'itemIds']).filter(childId => childId !== id)),
      })
    }
  } catch (error) {
    logException(error, `Failed to get batch info for item ${id} (${batchId})`)
  }

  const itemCategory = selectCategoryById(state, categoryId)
  const blacklistedSource = selectUserBlacklistedSourceIds(state).includes(sourceId)

  return Immutable.Map({
    id,
    title,
    link,
    source,
    sourceId,
    blacklistedSource,
    category: categoryToCategoryItem(itemCategory, state.user.get('categories')),
    tags: selectTagItemsBySlugs(state, tags),
    timestamp,
    clicks,
    plus_votes,
    minus_votes,
    currentVote,
    currentReport,
    batch,
    custom,
    paywalled,
  })
}
