const ssrEnpdoint = 'https://fargate-front-server.prod.ampparit.com'
const webEndpoint = 'https://www.ampparit.com'
const isSSR = (typeof window === 'undefined')

/**
 * This is the final config object that will be exported.
 */
const config = {
  // apiUrl will differ depending on whether the code is executing in browser or in Node SSR:
  apiUrl: isSSR ? `${ssrEnpdoint}/api` : `${webEndpoint}/api`,

  // These are not accessed during SSR, so they point to the same url in both cases:
  assetUrl: 'https://cdn.ampparit.com',
  appVersionUrl: `${webEndpoint}/clientversion`,
  siteUrl: webEndpoint,
  socket: webEndpoint,

  // Normal config stuff
  port: 8080,
  displayAds: true,
  displayCooperationBanner: true,
  defaultLocation: 14264, // Helsinki
  facebookAppId: '133363990059595',
  gtmContainerId: 'GTM-TQZQTWT',

  // These get turned in to webpack DefinePlugin constants. If you disable a feature
  // by setting it false, the whole npm library will be excluded from final js bundle.
  webpack: {
    enableSentryIO: true,
    enableReduxDevtools: false,
    enableWhyDidYouRender: false,
  },

  // See https://docs.sentry.io/platforms/javascript/configuration/options/
  sentry: {
    dsn: 'https://af232dbc502582b3980daedbee10a050@o4507293114695680.ingest.de.sentry.io/4507293123149904',
    sampleRate: 1.0,
    maxBreadcrumbs: 20,
  },

  // See https://www.npmjs.com/package/universal-cookie
  // Httponly is false because client needs programmatic access to tempProfile and other cookies.
  // For added security, login cookie's httpOnly is overridden in server.js and set to true.
  cookieOptions: {
    maxAge: 3600 * 24 * 365,
    httpOnly: false,
    secure: true,
    path: '/',
    sameSite: 'lax',
  },

  // See https://ict-solution-catalog.almamedia.io/alma-account-core/docs/AlmaTunnus and
  // https://almamedia.atlassian.net/wiki/spaces/ALTU/pages/1418035254/Component+parameters
  almaTunnusConfig: {
    scope: 'openid profile email offline_access',
    realm: 'Almatunnus',
    domain: 'tunnus.almamedia.fi',
    audience: 'https://tunnus.almamedia.fi',
    clientID: '5rEguAmL88cIk7CsTXy3huAFzAWpqtwA',
    redirectUri: `${webEndpoint}/`,
    selfService: 'https://itsepalvelu.almamedia.fi',
  },
}

module.exports = config
