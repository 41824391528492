import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import { Helmet } from 'react-helmet'
import Sticky from 'react-stickynode'
import classnames from 'classnames'
import MoreItems from '../../components/lists/MoreItems'
import { createUrl, basicWildcardMatch } from '../../lib/utils'
import { itemIdsToItems } from '../../components/item/itemsTransformers'
import Loading from '../../components/util/Loading'
import { Cookies } from 'react-cookie'
import { isTempProfileModified } from '../../user/user'
import Item from '../../components/item/Item'
import {
  listQueueLimit,
  listSize,
  listStatsUpdateInterval,
} from '../../lib/items'
import ItemsListHeader from '../../components/lists/ItemsListHeader'
import Queue from '../../components/lists/Queue'
import { Ticker } from '../../components/ticker/Ticker'
import createItemsWithLabels from '../../lib/createItemsWithLabels'
import splitItemsToSegments from '../../lib/splitItemsToSegments'
import injectAdsToList from '../../ad/injectAdsToList'
import SidebarMenu from '../../components/layout/SidebarMenu'
import {
  BoxAd,
  PostContentAd,
  MOBILE_LIST_2,
  MOBILE_LIST_3,
  MOBILE_LIST_4,
  MOBILE_LIST_5,
  MOBILE_LIST_X,
  MobileLeaderboardAd,
  MobileListAd,
  AlmaAjoListAd,
  SkyscraperAd,
  SKYSCRAPER_2,
  NativeAd,
  LIST_AD_1,
} from '../../ad/Ad'
import Resize from '../../ad/Resize'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import '../../styles/items.pcss'
import {
  changeActiveList,
  fetchStatsForItems,
  InitializeSegmentList,
  LIST_NAME_NONE,
  LIST_TYPE_NONE,
} from '../../components/item/itemsActions'
import { selectActivePresetProfileId } from '../../selectors/profilesSelector'
import {
  selectBreakingNewsTagItem,
  selectTrendingTagItems,
} from '../../selectors/tagsSelector'
import {
  WEBSOCKET_FAILED,
  WEBSOCKET_RECOVERED,
} from '../../status/statusReducer'
import {
  selectIsUserLoggedIn,
  selectUserBlacklistedTagIds,
} from '../../selectors/userSelector'
import MainContent from '../../components/wrappers/MainContent'
import { selectDeviceType } from '../../selectors/statusSelector'
import shareImg from '../../assets/ampparit-share-news.jpg'
import CategoryRecommendations from '../../user/CategoryRecommendations'
import { refreshAllAds } from '../../ad/AlmaAd'
import PopularNewsListBox from '../../components/widgets/PopularNewsListBox'
import TopNotification from '../../user/TopNotification'
import SaveTempProfileLink from '../../components/ui/SaveTempProfileLink'
import { CooperationBannerList } from '../../ad/CooperationBanner'
import LoginPromtItem from '../../components/item/LoginPromtItem'
import BreakingNews from '../../components/widgets/BreakingNews'
import TrendingTagNewsListBox from '../../components/widgets/TrendingTagsNewsListBox'


const NewsListView = ({
  allItems,
  blacklistedTagIds,
  breakingItems,
  breakingShouldUpdate,
  breakingTopic,
  category,
  deviceType,
  emptySegments,
  failedToLoad,
  getListH1,
  getListMetaDescription,
  getListOgDescription,
  getListOgImage,
  getListOgTitle,
  getListTitle,
  handleFetchNext,
  items,
  itemsQueuedTotal,
  itemIds,
  listBlacklisted,
  listEnd,
  loading,
  loadingNext,
  location,
  notFound,
  onFetchBatchItems,
  onListDidUpdate,
  path,
  personalListShouldRefresh,
  queuedItems,
  queuedItemsCount,
  refreshList,
  renderBlacklistedNotification,
  renderEmpty,
  renderFailedToLoad,
  renderInstructions,
  renderListOptions,
  renderNewsListWidgets,
  setActiveList,
  shouldRefresh,
  tag,
  tickers,
  timestamp,
  trendingFailedToLoad,
  trendingTags,
  unqueueItems,
  websocketStatus,
}) => {

  const [segments, setSegments] = useState(Immutable.List())
  const [shouldRefreshList, setShouldRefreshList] = useState(false)

  let adsUnqueuedItemsCount = 0

  const dispatch = useDispatch()
  const loggedIn = useSelector(selectIsUserLoggedIn)

  const title = getListTitle || 'Uutiset | Tuoreimmat uutiset | Ampparit.com'
  const heading = getListH1 || 'Uutiset'
  let ogTitle = getListOgTitle || 'Uutiset | Tuoreimmat uutiset | Ampparit.com'

  let canonicalUrl = createUrl(path)

  if (location?.query.share) {
    const sharedItem = allItems.find(
      (item) => item.id === location.query.share
    )
    canonicalUrl = canonicalUrl + '?share=' + location.query.share
    ogTitle =
      (sharedItem?.get('title')) ?? 'Jaettua uutista ei löydy'
  }

  const getMetaDescription = () => {
    return getListMetaDescription ||
    'Ampparit.com tarjoaa Suomen kattavimman uutiskatsauksen. ' +
    'Klikkaa ja löydä kätevästi kaikki tuoreimmat uutiset!'
  }

  const getOgDescription = () => {
    return getListOgDescription
  }

  const getOgImage = () => {
    return getListOgImage || createUrl(shareImg)
  }

  const updateListItemStats = useCallback((updateQueue = false) => {
    const ids = updateQueue === true ? queuedItems.concat(itemIds) : itemIds
    if (ids && ids.size > 0) {
      dispatch(fetchStatsForItems(ids.toArray()))
    }
  }, [dispatch, itemIds, queuedItems])

  useEffect(() => {
    if (emptySegments) {
      setSegments(Immutable.List())
    }

    if (personalListShouldRefresh && !shouldRefreshList || shouldRefresh && !shouldRefreshList) {
      setShouldRefreshList(true)
    }
  }, [emptySegments, shouldRefreshList, personalListShouldRefresh, shouldRefresh])

  useEffect(() => {
    setActiveList()
    const intervalId = setInterval(() => {
      if (window.document.hidden) return
      updateListItemStats()
    }, listStatsUpdateInterval)
    return () => clearInterval(intervalId)

  }, [setActiveList, updateListItemStats, dispatch])

  useEffect(() => {
    if (emptySegments) dispatch(InitializeSegmentList(false))
    if (shouldRefreshList) {
      refreshList()
      setShouldRefreshList(false)
    }
  }, [emptySegments, shouldRefreshList, dispatch, refreshList, onListDidUpdate])

  const unsetActiveList = useCallback(() => {
    dispatch(changeActiveList(LIST_TYPE_NONE, LIST_NAME_NONE))
  }, [dispatch])

  useEffect(() => {
    return unsetActiveList
  }, [unsetActiveList])


  const requireRefresh = () => {
    return (
      websocketStatus === WEBSOCKET_FAILED ||
        websocketStatus === WEBSOCKET_RECOVERED ||
        itemsQueuedTotal >= listQueueLimit
    )
  }

  const handleAdsOnUnqueueItems = () => {
    adsUnqueuedItemsCount += queuedItemsCount

    if (adsUnqueuedItemsCount >= 5) {
      adsUnqueuedItemsCount = 0
      if (window.initAlmaAds) {
        window.initAlmaAds()
        refreshAllAds()
      }
    }
  }

  const handleUnqueueItems = () => {
    handleAdsOnUnqueueItems()

    if (requireRefresh) {
      refreshList()
    } else {
      unqueueItems()
      updateListItemStats(true)
    }
  }

  const showBreakingTopic =
    breakingTopic &&
    !blacklistedTagIds.contains(breakingTopic.get('id')) &&
    breakingItems.size > 0 &&
    path === '/'

  const topTicker = tickers.find(
    (ticker) =>
      !ticker.get('closed') &&
          ticker.get('position') === 'top' &&
          ticker.get('devices').contains(deviceType) &&
          ticker.get('pages').some((page) => basicWildcardMatch(page, path))
  )

  const createItem = (item) => {
    return (
      <Item
        key={ item.get('id') }
        item={ item }
        timestamp={ timestamp }
        onFetchBatchItems={ onFetchBatchItems }
        dispatch={ dispatch }
        loggedIn={ loggedIn }
        isTagView={ !!tag }
      />
    )
  }

  const segmentedItems = splitItemsToSegments(
    createItemsWithLabels(items, timestamp, createItem),
    segments
  )

  const isEmpty = () => {
    return items.size === 0
  }

  const getAdsForFirstSegment = () => {
    return [
      {
        component: <MobileLeaderboardAd />,
        index: 4,
      },
      {
        component: !category && !loggedIn ? <LoginPromtItem /> : null,
        index: 6,
      },
      {
        component: (
          <Fragment>
            <TrendingTagNewsListBox
              trending={ trendingTags }
              failedToLoad={ trendingFailedToLoad }
              wrappingClass='news-list-box'
            />
            <PopularNewsListBox category={ category } tag={ tag } />
          </Fragment>
        ),
        index: 8,
      },
      {
        component: <NativeAd adUnitId={ LIST_AD_1 } />,
        index: 12,
      },
      {
        component: <MobileListAd adUnitId={ MOBILE_LIST_2 } />,
        index: 16,
      },
      {
        component: <CategoryRecommendations dispatch={ dispatch } />,
        index: 15,
      },
      {
        component: (
          <div className='placeholder-wrapper'>
            <Resize minBreakpoint={ 897 }>
              <div id='almad-list-2' path={ path } /> { /* This can be video ad */ }
            </Resize>
          </div>
        ),
        index: 20,
      },
      {
        component: (
          <CooperationBannerList title='Alma Ajon kumppani' path={ path }>
            <AlmaAjoListAd adUnitId={ MOBILE_LIST_3 } />
          </CooperationBannerList>
        ),
        index: 28,
      },
      {
        component: (
          <CooperationBannerList title='Alma Ajon kumppani' path={ path }>
            <AlmaAjoListAd adUnitId={ MOBILE_LIST_4 } />
          </CooperationBannerList>
        ),
        index: 36,
      },
      {
        component: <MobileListAd adUnitId={ MOBILE_LIST_5 } />,
        index: 40,
      },
      {
        component: path === '/' && (
          <CooperationBannerList
            src='https://feed.etuovi.com/etuovi/html/eo-2022-300x300-kaupungit_ampparit.html'
            title='Yhteistyössä Etuovi.com:'
            iframeTitle='Etuovi.com mainos'
            path={ path }
          />
        ),
        index: 54,
      },
      {
        component: (
          <CooperationBannerList title='Alma Ajon kumppani' path={ path }>
            <AlmaAjoListAd adUnitId='almad-list-6' />
          </CooperationBannerList>
        ),
        index: 64,
      },
      {
        component: <MobileListAd adUnitId='almad-list-7' />,
        index: 72,
      },
    ]
  }

  const getAdsForFollowingSegments = (segment) => {
    /**
       * After 'Lisää uutisia - More News' click set ad ids for following ads.
       * Decided for viewabililty reasons to limit ad ids to 7 maximum.
       */

    let upperAdId = 8
    let lowerAdId = 9

    if (segment++) {
      upperAdId = upperAdId + 2
      lowerAdId = lowerAdId + 2
    }

    return [
      {
        // Set null after 3rd "Lisää uutisia" button click
        component:
            segment < 3 ? (
              <MobileListAd adUnitId={ MOBILE_LIST_X + upperAdId } />
            ) : null,
        index: 8,
      },
      {
        // Set null after 3rd "Lisää uutisia" button click
        component:
            segment < 3 ? (
              <MobileListAd adUnitId={ MOBILE_LIST_X + lowerAdId } />
            ) : null,
        index: 35,
      },
    ]
  }

  const renderNewsList = (segmentedItems) => {
    return (
      <Loading loading={ loading }>
        { injectAdsToList(segmentedItems.first(), getAdsForFirstSegment()) }
      </Loading>
    )
  }

  const handleClickMore = () => {

    if (window.ALMA.ad.loadAd) {
      window.ALMA.ad.loadAd('almad-aside-left-2', { reload: true })
      window.ALMA.ad.loadAd('almad-aside-right-5', { reload: true })
      window.ALMA.ad.loadAd('almad-post-content-1', { reload: true })
    }

    setSegments(segments.push(items.last().get('id')))

    handleFetchNext(
      items.minBy((item) => item.get('timestamp')).get('timestamp')
    )
  }

  const tempProfileModified = () => {
    const cookies = new Cookies()
    return isTempProfileModified(cookies)
  }

  if (notFound === true) {
    return false
  }

  return (
    <div
      className={ classnames('container', 'item-list', {
        'list-blacklisted': listBlacklisted,
      }) }
    >
      <Helmet
        title={ title }
        heading={ heading }
        meta={ [
          { name: 'description', content: getMetaDescription() },
          { property: 'og:title', content: ogTitle },
          { property: 'og:image', content: getOgImage() },
          { property: 'og:description', content: getOgDescription() },
          { property: 'og:url', content: canonicalUrl },
        ] }
        link={ [{ rel: 'canonical', href: canonicalUrl }] }
      />

      <div className='main-container'>
        <SidebarMenu />

        <MainContent className={ classnames({ 'content_no-bg': !loading }) }>
          <div id='sticky-ad-bottom-boundary'>
            { listBlacklisted && renderBlacklistedNotification }

            { renderInstructions }

            { !loggedIn && tempProfileModified() && (
              <TopNotification>
                <SaveTempProfileLink dispatch={ dispatch } />
              </TopNotification>
            ) }

            { !showBreakingTopic ? null : (
              <BreakingNews
                dispatch={ dispatch }
                breakingTopic={ breakingTopic }
                breakingItems={ breakingItems }
                shouldUpdate={ breakingShouldUpdate }
              />
            ) }

            <ItemsListHeader heading={ heading } isTagView={ !!tag }>
              { renderListOptions() }

              { !loading && (
                <Queue
                  reload={ requireRefresh() }
                  count={ queuedItemsCount }
                  onUnqueueItems={ handleUnqueueItems }
                  isTagView={ !!tag }
                />
              ) }
            </ItemsListHeader>

            { topTicker && <Ticker ticker={ topTicker } dispatch={ dispatch } /> }

            { renderNewsListWidgets() }

            { failedToLoad && renderFailedToLoad() }

            { !failedToLoad && !loading && isEmpty()
              ? renderEmpty()
              : renderNewsList(segmentedItems) }
          </div>
          { segmentedItems.size === 1 &&
            items.size >= listSize &&
            !listEnd && (
            <MoreItems
              onMoreItemsClick={ handleClickMore }
              loading={ loadingNext }
            />
          ) }
        </MainContent>

        <div className='sidebar-container'>
          <GeneralSidebar category={ category } tag={ tag } />
        </div>
      </div>

      { segmentedItems.size > 1 && (
        <div
          className='main-container'
          id='sticky-segment-ad-bottom-boundary'
        >
          <div className='menu-container'>
            <Sticky
              top={ 50 }
              bottomBoundary='#sticky-segment-ad-bottom-boundary'
            >
              <SkyscraperAd adUnitId={ SKYSCRAPER_2 } />
            </Sticky>
          </div>
          <div className='fold-container outer-container'>
            { segmentedItems.rest().map((segmentItems, i) => {
              const isLast = i === segmentedItems.rest().size - 1
              return (
                <div className='main-container' key={ i }>
                  <div className='content content_no-bg'>
                    { injectAdsToList(
                      segmentItems,
                      getAdsForFollowingSegments(i)
                    ) }

                    { isLast && !listEnd && (
                      <MoreItems
                        onMoreItemsClick={ handleClickMore }
                        loading={ loadingNext }
                      />
                    ) }
                  </div>
                </div>
              )
            }) }
          </div>
          <div className='sidebar-container'>
            <Sticky
              top={ 50 }
              bottomBoundary='#sticky-segment-ad-bottom-boundary'
            >
              <BoxAd adUnitId='almad-aside-right-8' />
            </Sticky>
          </div>
        </div>
      ) }

      { !loading && path === '/' && <PostContentAd /> }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { list } = ownProps
  return {
    activePresetProfileId: selectActivePresetProfileId(state),
    allItems: state.items.items,
    blacklistedTagIds: selectUserBlacklistedTagIds(state),
    breakingItems: itemIdsToItems(state, state.breakingNews.get('itemIds')),
    breakingShouldUpdate: state.breakingNews.get('shouldUpdate'),
    breakingTopic: selectBreakingNewsTagItem(state),
    deviceType: selectDeviceType(state),
    emptySegments: state.items.shouldInitializeListSegments,
    failedToLoad: list.get('failedToLoad'),
    itemIds: list.get('itemIds'),
    items: itemIdsToItems(state, list.get('itemIds'), list.get('batches')),
    itemsQueuedTotal: list.get('itemsQueuedTotal'),
    listEnd: list.get('listEnd'),
    loading: list.get('loading'),
    loadingNext: list.get('loadingNext'),
    loggedIn: selectIsUserLoggedIn(state),
    queuedItems: list.get('itemQueueIds'),
    queuedItemsCount: list.get('itemQueueIds').size,
    shouldRefresh: list.get('shouldRefresh'),
    tickers: state.tickers.tickers,
    timestamp: state.time.get('timestamp'),
    trendingFailedToLoad: state.trending.get('failedToLoad'),
    trendingTags: selectTrendingTagItems(state),
    websocketStatus: state.status.get('websocket'),
  }
}

export default connect(mapStateToProps)(NewsListView)

/**
 * exact from 'prop-types-exact' cant be used before every news list component is migrated to functional component
 */
NewsListView.propTypes = { // eslint-disable-line react/prefer-exact-props
  allItems: ImmutablePropTypes.map.isRequired,
  blacklistedTagIds: ImmutablePropTypes.set.isRequired,
  breakingItems: ImmutablePropTypes.orderedSet.isRequired,
  breakingShouldUpdate: PropTypes.bool.isRequired,
  breakingTopic: ImmutablePropTypes.map,
  category: ImmutablePropTypes.record,
  deviceType: PropTypes.string.isRequired,
  emptySegments: PropTypes.bool.isRequired,
  failedToLoad: PropTypes.bool.isRequired,
  getListH1: PropTypes.string.isRequired,
  getListMetaDescription: PropTypes.string.isRequired,
  getListOgDescription: PropTypes.string.isRequired,
  getListOgImage: PropTypes.string.isRequired,
  getListOgTitle: PropTypes.string.isRequired,
  getListTitle: PropTypes.string.isRequired,
  handleFetchNext: PropTypes.func.isRequired,
  items: ImmutablePropTypes.orderedSet.isRequired,
  itemsQueuedTotal: PropTypes.number.isRequired,
  itemIds: ImmutablePropTypes.set.isRequired,
  listBlacklisted: PropTypes.bool,
  listEnd: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingNext: PropTypes.bool.isRequired,
  location: ImmutablePropTypes.map,
  notFound: PropTypes.bool,
  onFetchBatchItems: PropTypes.func.isRequired,
  onListDidUpdate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  personalListShouldRefresh: PropTypes.bool,
  queuedItems: ImmutablePropTypes.orderedSet.isRequired,
  queuedItemsCount: PropTypes.number.isRequired,
  refreshList: PropTypes.func.isRequired,
  renderBlacklistedNotification: PropTypes.func,
  renderEmpty: PropTypes.func.isRequired,
  renderFailedToLoad: PropTypes.func.isRequired,
  renderInstructions: PropTypes.func,
  renderListOptions: PropTypes.func,
  renderNewsListWidgets: PropTypes.func.isRequired,
  setActiveList: PropTypes.func.isRequired,
  shouldRefresh: PropTypes.bool.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    blacklisted: PropTypes.bool.isRequired,
    whitelisted: PropTypes.bool.isRequired,
  }),
  tickers: ImmutablePropTypes.list.isRequired,
  timestamp: PropTypes.number.isRequired,
  trendingFailedToLoad: PropTypes.bool.isRequired,
  trendingTags: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      whitelisted: PropTypes.bool.isRequired,
      blacklisted: PropTypes.bool.isRequired,
    })
  ).isRequired,
  unqueueItems: PropTypes.func.isRequired,
  websocketStatus: PropTypes.string.isRequired,
}

