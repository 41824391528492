import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { fetchAllTags } from '../../components/item/tagsActions'
import MainContent from '../../components/wrappers/MainContent'
import TopNotification from '../../user/TopNotification'
import Switch from '../../components/ui/Switch'
import SidebarMenu from '../../components/layout/SidebarMenu'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import './allTagsView.pcss'
import ListTitle from '../../components/lists/ListTitle'
import ArrowToggle from '../../components/util/ArrowToggle'
import { selectAllTagsItems, selectTrendingTagItems, selectTagsLoading, selectTagsFailedToLoad, selectAllTagsLoading, selectAllTagsFailedToLoad } from '../../selectors/tagsSelector'
import Loading from '../../components/util/Loading'
import UnableToLoad from '../../components/messages/UnableToLoad'

/*
 * Note: structure and styles for Trending tags and Search results used to be different but now they are the same (except for the sticky header).
 * It's fine this way, but if you have to work on this component in the future, you might want to consider refactoring to unify classNames.
 */

const AllTagsView = () => {
  const dispatch = useDispatch()
  const allTags = useSelector(selectAllTagsItems)
  const trendingIds = useSelector(selectTrendingTagItems).map(tag => tag.get('id')) // eslint-disable-line unicorn/prefer-set-has
  const loading = useSelector(selectTagsLoading)
  const failedToLoad = useSelector(selectTagsFailedToLoad)
  const allTagsLoading = useSelector(selectAllTagsLoading)
  const allTagsFailedToLoad = useSelector(selectAllTagsFailedToLoad)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortColumn, setSortColumn] = useState('itemsCount')
  const [sortDirection, setSortDirection] = useState('desc')

  useEffect(() => {
    dispatch(fetchAllTags())
  }, [dispatch])

  const handleSearchChange = (event) => setSearchTerm(event.target.value)

  const handleSortClick = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection(column === 'name' ? 'asc' : 'desc')
    }
  }

  const handleSortName = () => handleSortClick('name')
  const handleSortItems = () => handleSortClick('itemsCount')
  const handleSortWhiteListed = () => handleSortClick('whiteListedCount')
  const handleSortBlackListed = () => handleSortClick('blackListedCount')

  const sortedTags = useMemo(() => {
    return allTags.filter(tag =>
      tag.get('name').includes(searchTerm.toLowerCase())
    ).sort((a, b) => {
      const aValue = a.get(sortColumn)
      const bValue = b.get(sortColumn)
      if (sortColumn === 'name') {
        return sortDirection === 'asc' ? aValue.localeCompare(bValue, 'fi') : bValue.localeCompare(aValue, 'fi')
      }
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue
    })
  }, [allTags, searchTerm, sortColumn, sortDirection])

  const trendingTags = allTags.filter(tag => trendingIds.includes(tag.get('id')))

  return (
    <div className='all-tags container' id='sticky-ad-bottom-boundary'>
      <div className='main-container'>
        <SidebarMenu />
        <MainContent>

          <ListTitle heading='Aihetunnisteet' />

          <div className='all-tags__info'>
            <TopNotification className='info-box'>
              <p>Seuraa helposti sinua kiinnostavia aiheita. Valitse tästä omaan uutislistaasi aiheet, joita haluat seurata. Voit myös halutessasi piilottaa aiheen, jota et halua nähdä. Klikkaamalla aihetunnistetta löydät nipun uutisotsikoita sinua kiinnostavasta aiheesta.</p>
            </TopNotification>
          </div>

          <article className='all-tags__page'>
            <Helmet
              title='Aihetunnisteet'
              meta={ [
                { name: 'description', content: 'Tuoreimmat uutiset ja kovimmat puheenaiheet yhdestä paikasta. Ampparit käyttää aihetunnisteita, jotta kiinnostavien uutisten löytäminen olisi helpompaa.' },
                { property: 'og:title', content: 'Aihetunnisteet \u2013 Ampparit.com' },
                { property: 'og:description', content: 'Tuoreimmat uutiset ja kovimmat puheenaiheet yhdestä paikasta. Ampparit käyttää aihetunnisteita, jotta kiinnostavien uutisten löytäminen olisi helpompaa.' },
              ] }
            />

            <div className='all-tags__pinned'>
              <h3 className='all-tags__pinned-title'>Pinnalla olevat</h3>
              <div className='all-tags__pinned-container'>
                <table className='all-tags__pinned-table'>

                  <thead className='all-tags__pinned-table-head'>
                    <tr>
                      <th scope='col'>Aihetunniste</th>
                      <th scope='col'>Uutisia</th>
                      <th scope='col'>Seuraajia</th>
                      <th scope='col'>Piilottajia</th>
                    </tr>
                  </thead>
                  <tbody className='all-tags__pinned-table-body'>
                    { loading ? (
                      <tr>
                        <td colSpan='4'>
                          <Loading loading={ loading } />
                        </td>
                      </tr>
                    ) : (
                      trendingTags.map(renderTagRow)
                    ) }
                    { failedToLoad &&
                      <tr>
                        <td colSpan='4'>
                          <UnableToLoad errorText='Pinnalla olevia aihetunnisteita ei saatu ladattua.' />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div className='all-tags__search-input-container'>
              <div className='all-tags__search-input-header'>
                <h3>Kaikki aihetunnisteet</h3>
                <span className='all-tags__search-input-header__tag-count'>{ `(${ allTags.size } kpl)` }</span>
              </div>
              <input
                className='all-tags__search-input'
                type='text'
                placeholder='Etsi aihetunnisteita'
                aria-label='Etsi aihetunnisteita'
                value={ searchTerm }
                onChange={ handleSearchChange }
              />

              <div className='all-tags__search-results'>

                <table className='all-tags__search-results-table'>
                  <thead className='all-tags__search-results-table-head'>
                    <tr>
                      <th scope='col' onClick={ handleSortName }>
                        <span>Aihetunniste</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'name' && sortDirection === 'asc' }
                          onToggleAction={ handleSortName }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä tunnisteet nousevasti' : 'Järjestä tunnisteet laskevasti' }
                        />
                      </th>
                      <th scope='col' onClick={ handleSortItems }>
                        <span>Uutisia</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'itemsCount' && sortDirection === 'asc' }
                          onToggleAction={ handleSortItems }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä uutisten määrällä nousevasti' : 'Järjestä uutisten määrällä laskevasti' }
                        />
                      </th>
                      <th scope='col' onClick={ handleSortWhiteListed }>
                        <span>Seuraajia</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'whiteListedCount' && sortDirection === 'asc' }
                          onToggleAction={ handleSortWhiteListed }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä seuraajien määrällä nousevasti' : 'Järjestä seuraajien määrällä laskevasti' }
                        />
                      </th>
                      <th scope='col' onClick={ handleSortBlackListed }>
                        <span>Piilottajia</span>
                        <ArrowToggle
                          toggleUp={ sortColumn === 'blackListedCount' && sortDirection === 'asc' }
                          onToggleAction={ handleSortBlackListed }
                          screenReaderMessage={ sortDirection === 'asc' ? 'Järjestä piilottajien määrällä nousevasti' : 'Järjestä piilottajien määrällä laskevasti' }
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody className='all-tags__search-results-table-body'>
                    { loading ? (
                      <tr>
                        <td colSpan='4'>
                          <Loading loading={ allTagsLoading } />
                        </td>
                      </tr>
                    ) : (
                      sortedTags.size > 0 ? sortedTags.map(renderTagRow) : renderNoResult()
                    ) }
                    { allTagsFailedToLoad &&
                      <tr>
                        <td colSpan='4'>
                          <UnableToLoad errorText='Aihetunnisteita ei saatu ladattua.' />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </article>
        </MainContent>
        <div className='sidebar-container'>
          <GeneralSidebar />
        </div>
      </div>
    </div>
  )

  function renderTagRow(tag) {
    return (
      <tr key={ tag.get('id') }>
        <td>
          <Switch tag={ tag } />
        </td>
        <td>{ tag.get('itemsCount') }</td>
        <td>{ tag.get('whiteListedCount') }</td>
        <td>{ tag.get('blackListedCount') }</td>
      </tr>
    )
  }

  function renderNoResult() {
    return (
      <tr>
        <td colSpan='4'>
          <span className='all-tags__search-results-table__no-results'>Ei tuloksia</span>
        </td>
      </tr>
    )
  }
}

export default AllTagsView
