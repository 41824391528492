import { isAnyTagBlacklisted } from './tags'
import { selectCategoryBySlug, selectCategoryById, selectCategoryHierarchy, selectIsCategoryBlacklisted } from '../selectors/categoriesSelector'
import {
  selectIsUserLoggedIn,
  selectUserWhitelistedCategoryIds,
  selectUserWhitelistedTagIds,
  selectUserBlacklistedSourceIds,
  selectUserSubscribedSourceIds,
  selectUserWordFilters,
} from '../selectors/userSelector'
import {
  queueCategoryItem,
  queueItem,
  queueTagItem,
  LIST_NAME_FEATURED,
  LIST_NAME_NEWEST,
  LIST_NAME_PAYWALLED,
  LIST_NAME_PERSONAL,
  LIST_TYPE_CATEGORY,
  LIST_TYPE_MAIN,
  LIST_TYPE_TAG,
} from '../components/item/itemsActions'
import { queuePersonalItem } from '../user/userActions'

export const listSize = 72

// Max num of items that can be loaded to specific list via. queue.
// After this limit is reached, the list should be refreshed and queue ignored.
export const listQueueLimit = 100

export const listStatsUpdateInterval = 300000 //= 5 minutes in ms


/**
 * Check if the title is valid based on word filters.
 * @param {Immutable.OrderedSet<string>} filters
 * @param {string} title
 * @returns {boolean}
 */
function isTitleBannedByWordFilters(filters, title) {
  return filters.some(expr => {
    if (expr.length > 1) {
      const regex = expr.endsWith('*') ? `\\b${expr.slice(0, -1)}` : `\\b${expr}\\b`
      return title.match(new RegExp(regex, 'gi'))
    }
    if (expr.length === 1) {
      return title.includes(expr)
    }
    return false
  })
}



/**
 * Check if the item's category, tags or source is blacklisted by user
 * @todo this should be a selector function in items/userSelectors.js
 * @param item
 * @param state
 * @returns boolean
 */
export function itemIsBlacklisted(item, state) {
  const blacklistedTags = state.user.get('tags').get('blacklisted')
  const blacklistedSourceIds = selectUserBlacklistedSourceIds(state)
  const wordFilters = selectUserWordFilters(state)

  return (
    selectIsCategoryBlacklisted(state, item.categoryId) ||
    isAnyTagBlacklisted(blacklistedTags, item.tags) ||
    blacklistedSourceIds.contains(item.sourceId) ||
    isTitleBannedByWordFilters(wordFilters, item.title)
  )
}


/**
 * Returns `true` if item must not be shown on current list due to being paywalled
 * @returns {boolean}
 */
function isItemPaywallFiltered(state, item) {
  const listType = state.items.activeList.get('listType')
  const listName = state.items.activeList.get('listName')

  if (!item.isPaywalled || selectUserSubscribedSourceIds(state).contains(item.sourceId)) {
    return false
  }
  if (listType === LIST_TYPE_MAIN && listName === LIST_NAME_PAYWALLED && !selectIsUserLoggedIn(state)) {
    return false
  }
  if (listType === LIST_TYPE_MAIN && listName === LIST_NAME_NEWEST && !selectIsUserLoggedIn(state)) {
    return false
  }
  return true
}



/**
 * Sends a new item to the active list
 * @todo this code should be moved to itemsActions.js
 */
export function sendItemToActiveList(item, state, dispatch) {
  const itemTagIds = item.tags.map(tag => tag.id)
  const hasWhitelistedTags = selectUserWhitelistedTagIds(state).intersect(itemTagIds).size > 0
  const isWhitelistedCategory = selectUserWhitelistedCategoryIds(state).contains(item.categoryId)
  const listType = state.items.activeList.get('listType')
  const listName = state.items.activeList.get('listName')

  if (isItemPaywallFiltered(state, item)) {
    return
  }

  switch (listType) {

    case LIST_TYPE_MAIN:
      switch (listName) {
        case LIST_NAME_FEATURED:
          if (item.level === 1 || isWhitelistedCategory || hasWhitelistedTags) {
            dispatch(
              queueItem(item, LIST_NAME_FEATURED)
            )
          }
          break

        case LIST_NAME_PAYWALLED:
          if (item.isPaywalled) {
            dispatch(
              queueItem(item, LIST_NAME_PAYWALLED)
            )
          }
          break

        case LIST_NAME_NEWEST:
          dispatch(
            queueItem(item, LIST_NAME_NEWEST)
          )
          break

        case LIST_NAME_PERSONAL:
          if (isWhitelistedCategory || hasWhitelistedTags) {
            dispatch(queuePersonalItem(item))
          }
          break
      }
      break

    case LIST_TYPE_TAG:
      item.tags.forEach(tag => {
        if (tag.slug === listName) {
          dispatch(queueTagItem(item, tag.slug))
        }
      })
      break

    case LIST_TYPE_CATEGORY: {
      const listCategory = selectCategoryBySlug(state, listName)
      const itemCategory = selectCategoryById(state, item.categoryId)
      const hierarchy = selectCategoryHierarchy(state, itemCategory)
      if (hierarchy.contains(listCategory)) {
        dispatch(queueCategoryItem(item, listName))
      }
      break
    }
    default:
      return

  }
}
