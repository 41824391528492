import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import MainContent from '../../components/wrappers/MainContent'
import './infoViews.pcss'

export default class AboutUsView extends Component {
  render() {
    return (
      <MainContent>
        <article className='info-page'>
          <Helmet title='Tietoa palvelusta &ndash; Ampparit.com' />
          <h1>Suomen klikatuimmat jutut yhdessä palvelussa</h1>

          <p>
            Ampparit.com on ilmainen <b>uutispalvelu</b>, joka kokoaa yhteen laajan uutisvirran <b>yli 250 kotimaisesta lähteestä</b>.
            Uutisten lisäksi Ampparit sisältää <b>sääennusteet yli 1000 paikkakunnalle</b> ympäri maailmaa.
            Palvelu toimii kaikilla päätelaitteilla sekä Android- ja iPhone-sovelluksina.
          </p>

          <Link to='/palaute'>Ota yhteyttä</Link>
        </article>
      </MainContent>
    )
  }
}
