import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import './footer.pcss'

export default class Footer extends PureComponent {

  render() {
    return (
      <footer className='footer'>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Yhteystiedot</h5>
            <p>
              <span className='footer__link'>Ampparit Uutispalvelut</span><br />
              <span className='footer__link'>ampparit@ampparit.com</span><br />
              <Link className='footer__link' to='/palaute'>Lähetä palautetta</Link>
            </p>
          </section>
        </div>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Tietoa Amppareista</h5>
            <p>
              <a className='footer__link' href='https://www.almamedia.fi/alma-media-konsernin-uutis-hyoty-ja-ajanvietesisaltojen-kaytto-ja-sopimusehdot/' target='_blank' rel='noopener noreferrer'>Käyttöehdot</a><br />
              <Link className='footer__link' to='/tietoa/ukk'>Usein kysytyt kysymykset</Link><br />
              <Link className='footer__link' to='/tietoa-palvelusta'>Tietoa palvelusta</Link><br />
              <Link className='footer__link' to='/tietoa/lahteet'>Uutislähteet</Link><br />
              <Link className='footer__link' to='/sovellus'>Mobiilisovellus</Link><br />
            </p>
          </section>
        </div>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Tietoa lähdemedioille</h5>
            <p>
              <Link className='footer__link' to='/tietoa/lahteille'>Ohjeita uutislähteille</Link><br />
              <Link className='footer__link' to='/tietoa/mukaan'>Mukaan Amppareihin</Link><br />
            </p>
          </section>
        </div>

        <div className='footer__column'>
          <section>
            <h5 className='footer__title'>Mainosta Amppareissa</h5>
            <p>
              <a className='footer__link' href='https://www.almamedia.fi/mainostajat/mediamyynnin-yhteystiedot/alma-mediaratkaisut' target='_blank' rel='noopener noreferrer'>Mediamyynti</a><br />
              <a className='footer__link' href='https://www.almamedia.fi/mainostajat/mediat-ja-palvelut/valtakunnalliset/ampparit/' target='_blank' rel='noopener noreferrer'>Mediatiedot</a><br />
            </p>
          </section>
        </div>
      </footer>
    )
  }
}
