import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import { reportClick } from './itemsActions'


export default class ItemLink extends PureComponent {

  render() {
    const { item, trackingClass } = this.props

    const tagCsvStr = item.get('tags').map(tag => tag.get('name')).join(',')

    return (
      <a
        href={ item.get('link') }
        target='_blank'
        className={ trackingClass }
        rel='noopener'
        data-cat={ item.getIn(['category', 'name'], '') }
        data-cat-id={ item.getIn(['category', 'id'], '') }
        data-tags={ tagCsvStr }
        onClick={ this.handleClick }
        onMouseUp={ this.handleMouseUp }
      >
        { item.get('title') }
      </a>
    )
  }

  handleClick = () => {
    const { item, dispatch, gaItemClickUiElement } = this.props
    dispatch(reportClick(item, gaItemClickUiElement))
  }

  handleMouseUp = (e) => {
    if (e.button === 1) { // middle mouse button, ie. open link in new tab
      this.handleClick()
    }
  }
}

ItemLink.propTypes = exact({
  item: ImmutablePropTypes.map.isRequired,
  trackingClass: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  gaItemClickUiElement: PropTypes.string.isRequired,
})
