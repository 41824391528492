import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { setStatus } from '../../status/statusActions'
import {
  changeActiveList,
  fetchCategoryItems,
  fetchCategoryItemsBatch,
  fetchCategoryItemsNext,
  unqueueCategoryItems,
  LIST_NAME_NONE,
  LIST_TYPE_CATEGORY,
} from '../../components/item/itemsActions'
import CategoryItemsEmpty from './CategoryItemsEmpty'
import TopNotification from '../../user/TopNotification'
import {
  selectIsUserLoggedIn,
  selectUserBlacklistedCategoryIds,
  selectUserWhitelistedCategoryIds,
} from '../../selectors/userSelector'
import NewsListWidgets from '../../components/lists/NewsListWidgets'
import { selectCategoryHierarchy, selectCategoryBySlug } from '../../selectors/categoriesSelector'
import ListOptions from '../../components/ui/ListOptions'
import { fetchPopular } from '../../components/item/popularActions'
import NewsListView from './NewsListView'
import { CATEGORY_ID_PAIKALLISUUTISET, CATEGORY_ID_UUTISET } from '../../lib/constants'

@provideHooks({
  fetch: ({ dispatch, getState, params: { category } }) => {
    const state = getState()
    const catRecord = selectCategoryBySlug(state, category)
    if (!catRecord) {
      return dispatch(setStatus(404))
    }

    dispatch(changeActiveList(LIST_TYPE_CATEGORY, category))

    // Local news are so heavily paywalled that to have reasonably fresh content we include paywalled items by default.
    // Logged in users are able control paywalls in their settings so this defauld does not apply to them.
    const categoryHierarchy = selectCategoryHierarchy(state, catRecord)
    const isLocal = categoryHierarchy.getIn([0, 'id']) === CATEGORY_ID_PAIKALLISUUTISET
    const isLoggedIn = selectIsUserLoggedIn(state)
    const params = isLocal && !isLoggedIn ? { paywalled: 'any' } : {}
    return Promise.all([
      dispatch(fetchPopular(300, { categoryId: catRecord.id })),
      dispatch(fetchCategoryItems(category, params)),
    ])
  },
})
export class CategoryView extends Component {
  constructor(props) {
    super(props)
    this.setActiveList = this.setActiveList.bind(this)
  }

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    category: ImmutablePropTypes.recordOf({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      parentId: PropTypes.number.isRequired,
      treeLevel: PropTypes.number.isRequired,
      desc: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      h1: PropTypes.string.isRequired,
      relatedCategoryIds: ImmutablePropTypes.orderedSet,
      ogTitle: PropTypes.string,
      ogDesc: PropTypes.string,
      ogImage: PropTypes.string,
    }).isRequired,
    itemsForCategory: ImmutablePropTypes.map,
    notFound: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    listBlacklisted: PropTypes.bool.isRequired,
    listWhitelisted: PropTypes.bool.isRequired,
    trendingFailedToLoad: PropTypes.bool,
    trending: ImmutablePropTypes.map,
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
    }).isRequired,
  }

  handleListDidUpdate(prevProps, props) {
    const { notFound, dispatch, category } = props

    if (notFound) {
      dispatch(setStatus(404))
      dispatch(changeActiveList(LIST_TYPE_CATEGORY, LIST_NAME_NONE))
    } else if (prevProps.category !== category) {
      dispatch(changeActiveList(LIST_TYPE_CATEGORY, category.get('slug')))
    }
  }

  setActiveList() {
    const { dispatch, category, notFound } = this.props

    if (notFound) {
      dispatch(changeActiveList(LIST_TYPE_CATEGORY, LIST_NAME_NONE))
    } else {
      dispatch(changeActiveList(LIST_TYPE_CATEGORY, category.get('slug')))
    }
  }

  refreshList = () => {
    const { dispatch, params: { category } } = this.props
    dispatch(fetchCategoryItems(category))
  }

  fetchNext = (timestamp) => {
    const { dispatch, params: { category } } = this.props
    dispatch(fetchCategoryItemsNext(category, timestamp))
  }

  handleFetchBatchItems = (batch) => {
    const { dispatch, category } = this.props
    dispatch(fetchCategoryItemsBatch(category, batch))
  }

  unqueueItems = () => {
    const { dispatch, params: { category } } = this.props
    dispatch(unqueueCategoryItems(category))
  }

  getTitle() {
    const { category } = this.props

    return category ? category.get('title') + ' | Tuoreimmat uutiset | Ampparit.com' : ''
  }

  getH1() {
    const { category } = this.props

    if (category) {
      return category.get('id') === CATEGORY_ID_UUTISET ? category.get('h1') : `${category.get('h1')} | Uutiset`
    }

    return ''
  }

  getOgTitle() {
    const { category } = this.props

    return category?.get('ogTitle')
  }

  getMetaDescription() {
    const { category } = this.props

    return category?.get('desc')
  }

  getOgDescription() {
    const { category } = this.props

    return category?.get('ogDesc')
  }

  getOgImage() {
    const { category } = this.props

    return category?.get('ogImage')
  }

  renderFailedToLoad = () => (
    <TopNotification>
      <p><b>Uutisten lataaminen epäonnistui.</b></p>
    </TopNotification>
  )

  renderBlacklistedNotification = () => (
    <TopNotification>
      <p><b>Olet piilottanut tämän kategorian.</b></p>
    </TopNotification>
  )

  renderListOptions = () => {
    const { category, dispatch, listWhitelisted, listBlacklisted } = this.props
    return (
      <ListOptions
        tag={ category }
        isCategory={ true }
        dispatch={ dispatch }
        whitelisted={ listWhitelisted }
        blacklisted={ listBlacklisted }
      />
    )
  }

  renderEmpty = () => <CategoryItemsEmpty category={ this.props.category } />

  renderNewsListWidgets = () => <NewsListWidgets category={ this.props.category } />

  render() {
    const { itemsForCategory, category, notFound, listBlacklisted, trendingFailedToLoad, trending, path } = this.props

    if (notFound) {
      return <TopNotification><p><b>Kategoriaa ei löytynyt.</b></p></TopNotification>
    }

    return (
      <NewsListView
        getListTitle={ this.getTitle() }
        getListH1={ this.getH1() }
        getListOgTitle={ this.getOgTitle() }
        getListMetaDescription={ this.getMetaDescription() }
        getListOgDescription={ this.getOgDescription() }
        getListOgImage={ this.getOgImage() }
        list={ itemsForCategory }
        loading={ false }
        listBlacklisted={ listBlacklisted }
        trendingFailedToLoad={ trendingFailedToLoad }
        trending={ trending }
        renderBlacklistedNotification={ this.renderBlacklistedNotification }
        renderListOptions={ this.renderListOptions }
        renderEmpty={ this.renderEmpty }
        renderNewsListWidgets={ this.renderNewsListWidgets }
        onFetchBatchItems={ this.handleFetchBatchItems }
        handleFetchNext={ this.fetchNext }
        category={ category }
        tag={ null }
        path={ path }
        refreshList={ this.refreshList }
        unqueueItems={ this.unqueueItems }
        renderFailedToLoad={ this.renderFailedToLoad }
        onListDidUpdate={ this.handleListDidUpdate }
        setActiveList={ this.setActiveList }
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { trending } = state

  const categorySlug = ownProps.params.category
  const itemsForCategory = state.items.categories.get(categorySlug)

  const category = selectCategoryBySlug(state, categorySlug)
  const isBlacklisted = category != null && selectUserBlacklistedCategoryIds(state).contains(category.id)
  const isWhitelisted = category != null && selectUserWhitelistedCategoryIds(state).contains(category.id)

  return {
    itemsForCategory,
    notFound: category == null,
    category,
    listBlacklisted: isBlacklisted,
    listWhitelisted: isWhitelisted,
    loggedIn: selectIsUserLoggedIn(state),
    trendingFailedToLoad: trending.get('failedToLoad'),
    trending: trending,
  }
}

export default connect(mapStateToProps)(CategoryView)
